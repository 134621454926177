/* NanumSquareNeo */

/* Noto Sans KR */
@font-face {
    font-family: 'NotoSansKR';
    font-style: normal;
    font-weight: 400;
    src: url(./notosans/NotoSansKR-Regular.woff2) format('woff2'),
    url(./notosans/NotoSansKR-Regular.woff) format('woff');
    font-display: swap;
    unicode-range: U+1100-11FF, U+3130-318F, U+A960-A97F, U+AC00-D7A3, U+D7B0-D7FF;
}

@font-face {
    font-family: 'NotoSansKR';
    font-style: normal;
    font-weight: 700;
    src: url(./notosans/NotoSansKR-Bold.woff2) format('woff2'),
    url(./notosans/NotoSansKR-Bold.woff) format('woff');
    font-display: swap;
    unicode-range: U+1100-11FF, U+3130-318F, U+A960-A97F, U+AC00-D7A3, U+D7B0-D7FF;
}

/* Roboto */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url(./roboto/Roboto-Regular.woff2) format('woff2'),
    url(./roboto/Roboto-Regular.woff) format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url(./roboto/Roboto-Bold.woff2) format('woff2'),
    url(./roboto/Roboto-Bold.woff) format('woff');
    font-display: swap;
}

/* Poppins */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url(./poppins/Poppins-Regular.woff2) format('woff2'),
    url(./poppins/Poppins-Regular.woff) format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    src: url(./poppins/Poppins-SemiBold.woff2) format('woff2'),
    url(./poppins/Poppins-SemiBold.woff) format('woff');
    font-display: swap;
}

/* NanumSquareNeo */
@font-face {
    font-family: 'NanumSquareNeo';
    font-style: normal;
    font-weight: 400;
    src: url(./nanumSquareNeo/NanumSquareNeoTTF-bRg.woff2) format('woff2'),
    url(./nanumSquareNeo/NanumSquareNeoTTF-bRg.woff) format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'NanumSquareNeo';
    font-style: normal;
    font-weight: 700;
    src: url(./nanumSquareNeo/NanumSquareNeoTTF-dEb.woff2) format('woff2'),
    url(./nanumSquareNeo/NanumSquareNeoTTF-dEb.woff) format('woff');
    font-display: swap;
}


@font-face {
    font-family: 'NanumSquareNeo';
    font-style: normal;
    font-weight: 400;
    src: url(./nanumSquareNeo/NanumSquareNeoTTF-bRg.woff2) format('woff2'),
    url(./nanumSquareNeo/NanumSquareNeoTTF-bRg.woff) format('woff');
    font-display: swap;
}

@font-face {
    font-family: 'NanumSquareNeo';
    font-style: normal;
    font-weight: 700;
    src: url(./nanumSquareNeo/NanumSquareNeoTTF-dEb.woff2) format('woff2'),
    url(./nanumSquareNeo/NanumSquareNeoTTF-dEb.woff) format('woff');
    font-display: swap;
}