* {
    outline: none;
}

p,
span {
    word-break: keep-all;
    font-family: 'NotoSansKR, Roboto';
    transform: rotate(0.04deg);
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}