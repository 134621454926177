@tailwind components;

@layer components {
    .text-gradient_pro {
        @apply bg-gradient-to-r from-font_pro02 to-font_pro text-transparent bg-clip-text
    }

    .bg-gradient_pro {
        @apply bg-gradient-to-r from-bg_pro_gradient01 to-bg_pro_gradient02
    }
}