@media (max-width: 1024px) {
    #event_progress > div:first-child .progress_point {
        text-align: left;
        left: -8px;
    }

    #event_progress > div:last-child .progress_point {
        text-align: right;
        left: auto;
        right: -8px;
    }
}
