@tailwind components;

@layer components {
    .layout-phone-480 {
        @apply w-full max-w-480px mx-auto h-full
    }

    .layout-desktop {
        @apply w-full max-w-[1366px] mx-auto h-full
    }

    .layout-desktop_1142 {
        @apply w-full max-w-[1142px] mx-auto h-full
    }

    .layout-full_desktop {
        @apply w-full mx-auto h-full
    }

    .default_page_padding {
        @apply page_padding_default_x page_padding_default_bottom page_padding_default_top
    }

    .page_padding_default_x {
        @apply px-[20px] sm:px-[10px]
    }

    .page_padding_default_top {
        @apply pt-[20px] sm:pt-[20px]
    }

    .page_padding_default_bottom {
        @apply pb-[90px] sm:pb-[60px]
    }

    .not_bg_scrollbar::-webkit-scrollbar {
        width: 8px;
    }

    .not_bg_scrollbar::-webkit-scrollbar-thumb {
        background-color: #C7C7C7;
        border-radius: 20px;
    }

    .not_bg_scrollbar::-webkit-scrollbar-thumb:hover,
    .not_bg_scrollbar::-webkit-scrollbar-thumb:active {
        background-color: #878787;
    }

    /* Typography */
    .typo-landing_heading1 {
        @apply font-landing text-landing_heading1 !important
    }

    .typo-landing_heading2 {
        @apply font-landing text-landing_heading2 !important
    }

    .typo-landing_display1 {
        @apply font-landing text-landing_display1 !important
    }

    .typo-landing_display2 {
        @apply font-landing text-landing_display2 !important
    }

    .typo-landing_body_large {
        @apply font-landing text-landing_body_large !important
    }

    .typo-landing_body_medium {
        @apply font-landing text-landing_body_medium !important
    }

    .typo-landing_body_small {
        @apply font-landing text-landing_body_small !important
    }

    .typo-landing_body_caption {
        @apply font-landing text-landing_body_caption !important
    }
}


tab {
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 600px) {
        padding-left: 14px;
        padding-right: 14px;
    }
}

.intercom-lightweight-app {
    z-index: 1000 !important;
}

html[style*="overflow: hidden"] .intercom-lightweight-app {
    opacity: 0 !important;
}

.intercom-lightweight-app-launcher-custom-icon-open {
    width: 24px;
    height: 24px;
}
